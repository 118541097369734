import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'shared/components/Container';
import RubricSection from '../FLLChallengeRubric/RubricSection';
import RubricFLLEInstructions from './instructions';
import ReviewingCriteria from './ReviewingCriteria';
import innovationOptions from './options';
import { connect } from 'react-redux';
import { Col, Row, Form } from 'antd';
import RadioBtn from 'shared/components/RadioButton';
import FeedbackComments from '../FLLChallengeRubric/FeedbackComments';
import { updateJudgingRubricFLLE } from 'features/EventOverviewContainer/redux/actions/judgingRubric';

const FormItem = Form.Item;
const moduleNameClassName = `h-2 heading--white`;
const moduleTitleClassName = `h-2 heading--charcoal p-l-40`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal';

const radioOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
];

const RubricForm = (props) => {
    const { t } = useTranslation();
    const { rubric, judgingRubricComments, updateRubric } = props;
    const [form] = Form.useForm();

    const initialFormValues = {};
    innovationOptions.map((v) => {
        v.fields.map((f) => {
            initialFormValues[f.id] = rubric[f.id];
        });
    });

    const handleChange = (field, e) => {
        const { target: { value } } = e;
        updateRubric(field, value);
    };

    return (
        <Container>
            <div className='c-rubric-upload'>
                <RubricFLLEInstructions
                    instructionsText={t('exploreReviewingSheetInstructionsLabel')}
                    collapsed={false}
                >
                    <ReviewingCriteria />
                </RubricFLLEInstructions>
            </div>

            <Form
                form={form}
                hideRequiredMark
                className='m-t-40'
                name='CoreValuesRubric'
                initialValues={initialFormValues}
            >
                {innovationOptions.map((v) => (
                    <div tabIndex={0} className='c-fll-rubric-form__section' key={v.id}>
                        <div>
                            <p className={`${moduleTitleClassName} fll_e_header`}>{t(v.title)}</p>
                            <div className='c-fll-rubric-form__section-content-wrapper'>
                                {v.fields.map((f) => (
                                    <fieldset className='c-fll-rubric-form' key={f.id}>
                                        <Row>
                                            <Col md={8} xs={24} className="p-l-40 p-r-40 p-t-20">
                                                <p key={v.id} style={{ fontWeight: 600 }} className={paragraphClassName}>{t(f.value)}</p>
                                            </Col>
                                            <Col md={8} xs={24} className="p-l-20 p-t-20">
                                                <p key={v.id} className={paragraphClassName}>{t(f.answear)}</p>
                                            </Col>
                                            <Col md={8} xs={24} className="p-l-40 p-r-40 p-t-20">
                                                <FormItem
                                                    name={f.id}
                                                    label={<span className='u-sr-only'>{t(v.description)}</span>}
                                                    colon={false}
                                                >
                                                    <RadioBtn
                                                        options={radioOptions}
                                                        onChange={(e) => handleChange(f.id, e)}
                                                        disabled={rubric.is_publishable || rubric.is_published}
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </Form>

            <FeedbackComments
                commentSection="flle"
                rubric={rubric}
                commentsData={judgingRubricComments}
            />
        </Container>
    );
};

const mapDispatchToProps = {
    updateRubric: updateJudgingRubricFLLE,
};

const mapStateToProps = (state) => {
    const { judgingRubric } = state;
    return {
        rubric: judgingRubric.fllEChallengeRubric,
        judgingRubricComments: judgingRubric.judgingRubricComments,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RubricForm);
    
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import RadioBtn from 'shared/components/RadioButton';
import { Col, Row, Form } from 'antd';
import { getJudgeCommentsForFLLE, getJudgingRubricFLLE } from 'features/EventOverviewContainer/redux/actions/judgingRubric';
import innovationOptions from '../../JudgingRubric/RubricForm/options';
import ReadOnlyFeedbackComments from './ReadOnlyFeedbackComments';
import EventModal from 'features/EventOverviewContainer/components/EventModal';
import { ClipLoader } from 'react-spinners';

const FormItem = Form.Item;
const moduleTitleClassName = `h-2 heading--charcoal p-l-40`;
const paragraphClassName = 'paragraph--large paragraph--charcoal paragraph--normal centered'
const normalText = 'paragraph--default paragraph--charcoal paragraph--normal centered';

const radioOptions = [
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' }
]

const FLLERubricResult = (props) => {
    const { t } = useTranslation()
    const { rubric, isLoadingRubric, isFetchingRubricComments, judgingRubricComments, digitalRubric, getFLLEComments, getFLLERubric} = props;
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const initialFormValues = {};

  useEffect(() => {
    if(rubric) {
      const updatedValues = {};
      innovationOptions.forEach((v) => {
        v.fields.forEach((f) =>{
          updatedValues[f.id] = rubric[f.id];
        });
      });
      form.setFieldsValue(updatedValues);
    }
  }, [rubric, form]);

    useEffect(() => {
      if(digitalRubric){
        getFLLEComments(digitalRubric);
        getFLLERubric(digitalRubric);
      }
    }, [digitalRubric])

    const openModal = () => {
      setVisible(true);
    }

    const closeModal = () => {
      setVisible(false);
    }

    if (isLoadingRubric || isFetchingRubricComments) {
      return (
        <div className='judging-results-feedback-inner'>
          <ClipLoader size={36} color='#0066B3' />
        </div>
      )
    }

    if (!rubric?.is_published) {
      return (
        <div className='judging-results-feedback-inner'>
          <p className={normalText}>
            { t('nonChallengeEmptyFeedbackLabel')}
          </p>
        </div>
      )
    }


    return (
      <div>
        <EventModal
          visible={visible}
          onCancel={closeModal}
          wrapClassName='teams-coaches-modal'
        >
          {<Form form={form} hideRequiredMark className='m-t-40' name='FLLERubric' initialValues={initialFormValues}>
            {innovationOptions.map(v => (
              <div tabIndex={0} className='c-fll-rubric-form__section' key={v.id}>
                <div>
                  <div className=''>
                    <p className={`${moduleTitleClassName} fll_e_header`}>{t(v.title)}</p>
                  </div>
                  <div className='c-fll-rubric-form__section-content-wrapper'>
                    {v.fields.map(f => {
                      return (
                        <fieldset className='c-fll-rubric-form' key={f.id}>
                          <div className='c-fll-rubric-form__section-content-legend-wrapper'>
                            <Row>
                              <Col md={8} xs={24} className="p-l-40">
                                <div className='m-t-20'>
                                  <p key={v.id} style={{ fontWeight: 600 }} className={paragraphClassName}>{t(f.value)}</p>
                                </div>
                              </Col>

                              <Col md={8} xs={24} className="p-l-20">
                                <div className='m-t-20'>
                                  <p key={v.id} className={paragraphClassName}>{t(f.answear)}</p>
                                </div>
                              </Col>

                              <Col md={8} xs={24} className="p-r-40 p-t-20">
                                <FormItem name={f.id} colon={false} label={<span className='u-sr-only'>{t(v.description)}</span>}>
                                    <RadioBtn
                                        options={radioOptions}
                                        name={t(f.legendTitle)}
                                        disabled={true}
                                    />
                                </FormItem>
                              </Col>
                            </Row>
                          </div>
                        </fieldset>
                      )}
                    )}
                  </div>
                </div>
              </div>
            ))}
            </Form>}
            <ReadOnlyFeedbackComments commentSection="flle" comments={judgingRubricComments} />
        </EventModal>
        <div className='judging-results-feedback__fll centered'>
          <div className='judging-results-feedback__rubric-form'>
            <button
              type='button'
              className='judging-results-feedback__rubric-modal-btn'
              onClick={openModal}
            >
              <img
                src='/FLL_E_Rubric.png'
                alt='judging rubric'
                height={153}
              />
            </button>
          </div>
        </div>
      </div>
    )
}

const mapDispatchToProps = {
  getFLLEComments: getJudgeCommentsForFLLE,
  getFLLERubric: getJudgingRubricFLLE,
}

const mapStateToProps = (state) => {
    const { judgingRubric: {
        isLoadingRubric,
        fllEChallengeRubric,
        judgingRubricComments,
        isFetchingRubricComments,
    } } = state;
    return {
        isLoadingRubric,
        rubric: fllEChallengeRubric,
        judgingRubricComments,
        isFetchingRubricComments
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FLLERubricResult);
// export default RubricForm